<template>
  <div class="view-magazine-post-page">
    <v-row justify="center">
      <v-col md="6">
        <div v-if="postObject" class="mx-2">
          <v-img
            v-if="postObject.image_file"
            :src="postObject.image_file['document']"
            contain
            height="300px"
          ></v-img>
          <div class="mt-2">
            <h2  class="primary--text">
              {{ postObject.title }}
            </h2>

            <p v-if="postObject.author" class="ma-0 mb-2">
              By {{ postObject.author }}
            </p>

            <v-divider class="mx-2"></v-divider>

            <p class="ma-0 mt-2"
            v-html="TextHelper.linkify(postObject.description)"
            >
            </p>
            <p class="caption grey--text pb-0 ma-0">
              {{ moment(postObject.created).format("Do MMM") }}
            </p>
          </div>
        </div>
      </v-col>
    </v-row>

    <intract-create-edit-sheet
      persistent
      v-if="currentUser.is_admin"
      title="Edit Magazine Post"
      :visible="postEditSheet.visible"
      @close="postEditSheet.visible = false; postEditSheet.editId = null;"
      :fields="formFields"
      enable-media
      :endpoint="endpoints.magazineViewSet"
      :edit-id="postEditSheet.editId"
      :data-object="postEditSheet.obj"
      :create-success-message="postEditSheet.editSuccessMessage"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (postEditSheet.obj = obj)"
    />

    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this post?"
      description="This action cannot be undone"
      @successCallback="deletePost"
      @failureCallback="showDeleteDialog = false"
      delete-dialog
    />
  </div>
</template>

<script>
import TextHelper from '@utils/text_helper';
import Mixins from "@utils/mixins";
import moment from "moment";
import EventBus from "@utils/event_bus";
import { mapActions } from "vuex";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";

export default {
  name: "ViewMagazinePost",
  mixins: [Mixins.essentials],
  components: {
    IntractCreateEditSheet,
  },
  data() {
    return {
      TextHelper,
      moment: moment,
      postObject: null,
      showDeleteDialog: false,
      postEditSheet: {
        visible: false,
        editSuccessMessage: "Post edited successfully!",
        editId: null,
        obj: {
          uploader: null,
          institution: null,
        },
      },
    };
  },
  computed: {
    magazinePostsEndpoint() {
      return this.endpoints.magazineViewSet;
    },
    canManage() {
      return this.postObject && this.currentUser.is_admin;
    },
    formFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        author: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Author Name (optional)",
          required: false,
          md: 12,
          max: 255,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        image_file: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: false,
          label: "Image (Optional)",
          helper: "JPG, PNG, GIF, etc",
        },
      };
    },
  },
  methods: {
    ...mapActions(["setAppBarOptions", "resetAppBarOptions"]),

    async deletePost() {
      this.showDeleteDialog = false;
      var url = this.endpoints.magazineViewSet + this.postObject.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Post successfully deleted",
          type: "success",
        });
        EventBus.$emit("magazine__post_deleted", this.postObject.id);
        this.$router.back();
      }
    },

    async getMagazinePost() {
      var url =
        this.endpoints.magazineViewSet + this.$route.params.postId + "/";
      this.postObject = await this.api.call(this.essentials, url);
      EventBus.$emit("magazine__post_updated", this.postObject);
    },
    async onRefresh() {
      this.getMagazinePost();
    },
    async setAppBar() {
      this.setAppBarOptions({
        title: this.appBarTitle,
        actions: [
          {
            id: 1,
            title: "Edit",
            icon: "mdi-pencil",
            onClick: () => {
              this.postEditSheet.visible = true;
              this.postEditSheet.editId = this.postObject.id;
            },
          },
          {
            id: 2,
            title: "Delete",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
        ],
      });
    },
  },
  async created() {
    this.postEditSheet.obj.uploader = this.currentUser.id;
    this.postEditSheet.obj.institution = this.currentInstitution.id;
    await this.getMagazinePost();
    if (this.canManage) this.setAppBar();
  },
  beforeDestroy() {
    this.resetAppBarOptions();
  },
};
</script>

<style>
</style>